<template>
  <div class="evaluation-page" v-if="is_show">
    <header class="border-bottom-1px df fdr alc">
      <img :src="item_img" alt="">
      <yd-rate slot="left" v-model="star" padding="0.24rem" size="0.64rem" active-color="#FF5252"></yd-rate>
    </header>
    <div class="content">
      <yd-textarea slot="right" v-model="content" placeholder="请输入您的评价" maxlength="100"></yd-textarea>
      <div class="img-list df fdr fww">
        <!--已上传的图片列表-->
        <div class="list-box" v-for="(item,index) in img_list" :key="index">
          <img class="upload-img" :src="item" alt="evaluation_img">
          <img class="close-img" @click.stop="deleteImg(index)" src="../../assets/icon_close_white.png" alt="">
        </div>
        <!--上传图片按钮-->
        <div class="upload-box" v-if="img_list.length<9">
          <img class="upload-bg" src="../../assets/icon-up-picture@3x.png" alt="">
          <input type="file" multiple="multiple" @change="doUpload($event)" accept="image/*">
        </div>
      </div>
    </div>
    <footer @click.stop="evaluateOrder">
      <MyButton title="提交" :is_disabled=false></MyButton>
    </footer>
  </div>
</template>

<script>
import MyButton from '@/components/button/button'
import {evaluateOrder} from "@/services/orderApi";
import {client} from '../../utils/alioss';
import md5 from "js-md5";
import { randomWord } from "@/utils/common";
import { getStore } from "@/utils/common";
import { customAccount } from "@/utils/custom";
export default {
  name: "evaluation",
  data() {
    return {
      order_id: '',
      item_img: this.$route.query.img,
      star: 5,//评级
      content: '',//评语
      img_url: '',
      img_list: [],
      year: '',
      month: '',
      is_show: false
    }
  },
  components: {
    MyButton
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 判断是否有token
      if (getStore('session_id') === null&& customAccount('VUE_APP_JY')) {
        vm.$dialog.loading.open('正在跳转登录...');
        setTimeout(() => {
            vm.$dialog.loading.close();
            vm.$router.replace({
              path:'login'
            })
        }, 1500);
        return false
      } else {
        vm.is_show = true
      }
    })
  },
  methods: {
    getParams() {
      this.order_id = this.$route.query.id ? Number.parseInt(this.$route.query.id) : '';
      if (!this.order_id) {
        this.$router.go(-1)
      }
    },
    /**
     * 上传
     * @param event
     * @returns {Promise<void>}
     */
    async doUpload(event) {
      const _this = this;
      const max_file_num = 9;//  文件数量上限
      const files = event.target.files;
      const fileLen = event.target.files.length;
      if (fileLen > max_file_num - _this.img_list.length) {
        this.$dialog.toast({
          mes: `只能选择${max_file_num - _this.img_list.length}张图片`,
        });
        return
      }
      _this.$dialog.loading.open('上传中...');
      if (fileLen && this.img_list.length <= 9) {
        for (let i = 0; i < fileLen; i++) {
          const file = files[i];
          let sign = _this.signature();
          let name = `Uploads/image/${sign}/${new Date().getTime()}${file.name}`;
          // 上传
          try {
            let clientObj = await client();
            let result = await clientObj.multipartUpload(name, file);
            _this.img_url = result.res.requestUrls[0].split('?')[0];
            this.$dialog.loading.close();
            this.$dialog.toast({
              mes: '成功',
              icon: 'success',
              timeout: 1500
            });
          } catch (e) {
            console.log(e);
          }
        }
      }

    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },
    signature() {
      let date = new Date();
      let y = date.getFullYear();
      let m = this.appendZero(date.getMonth() + 1);
      return `${y}-${m}`;
    },
    /**
     * 删除图片
     * @param index
     */
    deleteImg(index) {
      console.log(index);
      this.img_list.splice(index, 1);
    },
    async evaluateOrder() {
      try {
				const startRandom = randomWord(false, 4);
				const endRandom = randomWord(false, 4);
				const sign = `${startRandom}order_id:${this.order_id}${endRandom}`;
        let params = {
          order_id: this.order_id,
          star: this.star,
          content: this.content,
          photos: this.img_list.toString(),
					sign: md5(sign),
					random: `${startRandom}${endRandom}`,
					mobile: true
        };
        // console.log(params);
        if (!params.content) {
          this.$dialog.toast({
            mes: '请输入您的评价',
          });
          return
        }
        const res = await evaluateOrder(params);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.$router.replace({
            name:'evaluation_success',
          })
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
            timeout: 1000
          });
          this.$router.go(-1);
        }
      } catch (e) {

      }
    },
  },
  mounted() {
    this.getParams()
  },
  watch: {
    img_url(val) {
      if (val) {
        this.img_list.push(val);
      }
    }
  }
}
</script>

<style lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.evaluation-page {
  header {
    height: 1.88rem;
    padding-left: 0.3rem;
    background: @color-white;

    img {
      .wh(1.28rem, 1.28rem);
      margin-right: 0.88rem;

    }
  }

  .content {
    background: @color-white;
    min-height: 3.24rem;
    padding: 0.3rem 0.14rem 0.3rem 0.3rem;
    margin-bottom: 0.34rem;

    .yd-textarea {
      padding: 0;
    }

    .yd-textarea > textarea {
      height: 1.16rem;
      .sc(0.28rem, #333);
    }

    .yd-textarea > textarea::placeholder {
      .sc(0.28rem, @color-light-grey-s);
    }

    .img-list {
      margin-top: 0.3rem;

      .list-box {

        position: relative;

        .upload-img {
          .wh(1.6rem, 1.6rem);
          border-radius: 12px;
          margin-right: 0.16rem;
          margin-bottom: 0.3rem;
          object-fit: cover;
        }

        .close-img {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0.1rem;
          top: -0.1rem;
        }

      }

      .upload-box {
        position: relative;
        .wh(1.6rem, 1.6rem);

        input {
          position: absolute;
          .wh(1.6rem, 1.6rem);
          right: 0;
          top: 0;
          opacity: 0;
        }

        .upload-bg {
          .wh(1.6rem, 1.6rem)
        }
      }
    }


  }

}

</style>
